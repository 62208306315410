import axios from '../../utils/axios';

const actionTypes = {
    PAYMENT_CREATE: 'PAYMENT_CREATE',
    PAYMENT_CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
    PAYMENT_CREATE_ERROR: 'PAYMENT_CREATE_ERROR',
    PAYMENT_CREATE_POST: 'PAYMENT_CREATE_POST',
    PAYMENT_CREATE_POST_SUCCESS: 'PAYMENT_CREATE_POST_SUCCESS',
    PAYMENT_CREATE_POST_ERROR: 'PAYMENT_CREATE_POST_ERROR',
}

export const getPaymentsTypes = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/lk/payments_create');
            let selectedId;
            response.data.payment_method.forEach(item => {
                if (item.activ === true) {
                    selectedId = item.id
                }
            })
            dispatch({
                type: actionTypes.PAYMENT_CREATE_SUCCESS,
                payload: {
                    recommendedAmount: response.data.recomended_amount,
                    paymentMethod: response.data.payment_method,
                    minPayAmount: response.data.payment_method.min_pay_amount,
                    id: selectedId,
                    serviceRecurrent: response.data.service_recurrent
                }
            });
            return Promise.resolve(response)
        } catch (error){
            dispatch({
                type: actionTypes.PAYMENT_CREATE_ERROR,
                payload: {
                    message: error.payload.message
                }
            });
            return Promise.reject(error);
        }
    }
}

export const getPaymentInfo = (selectedParam) => {
    return  (dispatch) => {
        dispatch({
            type: actionTypes.PAYMENT_CREATE_POST
        })
        return axios
            .post('/api/lk/payments_create', {
                payment_type: selectedParam.selectedId,
                payment_sum: selectedParam.amount
        } )
            .then(res => {
                dispatch({
                    type: actionTypes.PAYMENT_CREATE_POST_SUCCESS,
                    payload: {
                        mode: res.data.mode,
                        qr: res.data.qr,
                        qr_id: res.data.qr_id,
                        status: res.data.status,
                        url: res.data.url,
                        error: res.data.error
                    }
                })
                return res;
            })
            .catch(() => {
            })
    }
}
