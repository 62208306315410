import React from 'react';

import style from './PaymentCardError.module.css'
export default function PaymentCardError ()  {
    setTimeout(function(){
        location.href = '/lk';
    }, 10000);

    return (
        <div className={style.root}>
            <div className={style.test}>Ошибка добавления карты</div>
        </div>
    )
}